$('img.svg').each(function(){
    var $img = $(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    $.get(imgURL, function(data) {
        var $svg = $(data).find('svg');

        if(typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }
        if(typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass+' replaced-svg');
        }
        $svg = $svg.removeAttr('xmlns:a');

        if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
        }
        $img.replaceWith($svg);

    }, 'xml');

});

$('.hamburger-box').click(function(e) {
  $('.mobile-panel').addClass('is-visible')
});

$('.close-panel').click(function(e) {
  $('.mobile-panel').removeClass('is-visible')
});

if(window.matchMedia('(min-width: 768px)').matches){
    $(window).scroll(function () {
      if ($(this).scrollTop() > 0) {
        $('.header').addClass("is-fixed");
      } else {
        $('.header').removeClass("is-fixed");
      }
    });
}
$(document).ready(function() {
  $('.scroll-link').click(function(event) {
    event.preventDefault();
    var id  = $(this).attr('href'),
    top = $(id).offset().top - 50;
    if($('.mobile-panel').is(':visible')){
        top = $(id).offset().top
        $('.mobile-panel').hide()
    }
    $('body,html').animate({scrollTop: top}, 1000);
  });
});


$('.review__tab__item').click(function (e) {
  e.preventDefault();
  var tab = $(this).attr('data-tab');
  $('.review__tab__item').removeClass('is-active');
  $(this).addClass('is-active');
  $('.review__content__item').removeClass('active');
  $('' + tab).addClass('active');
});

$('.mobile-tab-item').click(function(event) {
  var mobReview = $(this).next()
  if(mobReview.is(':hidden')){
    $('.review__content').slideUp(250)
    mobReview.slideDown(250)
  }
  else{
    mobReview.slideUp(250)
  }
});

$(".phone-input").mask("8(999)999-99-99")

$(function(){

  var field = new Array("e-mail", "phone");
      
  $("form").submit(function(e) {
    e.preventDefault() 
    var error=0; 
    $(this).find(":input").each(function() {
      for(var i=0;i<field.length;i++){ 
        if($(this).attr("name")==field[i]){ 

          if(!$(this).val()){
            $(this).closest('.form-group').addClass('is-invalid')
            error=1;          
          }
          else{
            $(this).closest('.form-group').addClass('is-valid')
          }
          
        }           
      }       
     })
     
    var email = $("#email").val();
    if(!isValidEmailAddress(email)){
      error=2;
      $("#email").closest('.form-group').addClass('is-invalid')
    }
    else{
      $("#email").closest('.form-group').addClass('is-valid')
    }
    
    if(error==0){ 
      return true;
    }
  })
});

function isValidEmailAddress(emailAddress) {
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  return pattern.test(emailAddress);
}